
import React from 'react'

import ProviderClass from './ProviderClass'
import TitleLogo from '../images/TitleLogo'


import { TITLE } from '../../common/constants'

const Title = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={TitleLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...TITLE}
    />
  </>
)

export default Title
