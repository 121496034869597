
import React from 'react'

import ProviderClass from './ProviderClass'
import HomeSecurityLogo from '../images/HomeSecurityLogo'


import { HOME_SECURITY } from '../../common/constants'

const HomeSecurity = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={HomeSecurityLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...HOME_SECURITY}
    />
  </>
)

export default HomeSecurity
