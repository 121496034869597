import './ProviderClass.scss'

import React, { useState } from 'react'
import { FaCaretRight, FaCaretDown } from 'react-icons/fa'
import Checkbox from '@material-ui/core/Checkbox'

import PropTypes from "prop-types"

const ProviderClass = (props) => {

  const {
    logoComponent,
    title,
    checked,
    updateChecked,
    description,
  } = props

  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)
  return (
    <>
      <div className={"ProviderClass"}>
        <div className={"ProviderClass__content"}>
          <div className={"ProviderClass__preview-content"}>
            <div className={"ProviderClass__icon"} onClick={toggleExpanded}>
              {logoComponent()}
            </div>
            <div className={"ProviderClass__title"} onClick={toggleExpanded}>
              <div className={"ProviderClass__class-name"}>
                {title}
              </div>
              <div
                className={"ProviderClass__expander"}
                onClick={toggleExpanded}
              >
                { expanded ? <FaCaretDown/> : <FaCaretRight/> }
              </div>
            </div>
            { !expanded &&
              <div className={"ProviderClass__connect"}>
                <div className={"ProviderClass__connect-check"}>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => updateChecked(e.target.checked)}
                    style={{ backgroundColor: 'transparent' }}
                  />
                </div>
                <div className={"ProviderClass__connect-label"}>
                  Connect Me
                </div>
              </div>
            }
          </div>
          {
            expanded && <>
              <div className={"ProviderClass__extended-content"}>
                <div className={"ProviderClass__details"}>
                  {description}
                </div>
              </div>
            </>
          }
          {
            expanded && (
              <div className={"ProviderClass__connect-extended"}>
                <label>
                  <Checkbox
                    checked={checked}
                    onChange={(e) => updateChecked(e.target.checked)}
                    style={{backgroundColor: 'transparent'}}
                  />
                  Connect me with partners in my area
                </label>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

ProviderClass.propTypes = {
  logoComponent: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  updateChecked: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
}

export default ProviderClass