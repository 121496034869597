import './Footer.scss'

import _ from 'lodash'

import React from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap'

import {TITLE_FOR_SELECTION} from '../common/constants'

const Footer = ({selections, email, updateEmail, onSubmit, visible, success}) => {

  if (_.isEmpty(selections)) {
    return <></>
  }


  var services = []

  selections.forEach(selection => services.push(TITLE_FOR_SELECTION[selection]))

  if (services.length >= 3) {
    services = [
      services[0],
      services[1],
      `${services.length - 2} other ${services.length === 3 ? 'industry' : 'industries'}`
    ]
  }

  if (services.length === 1) {
    services = <span><b>{services[0]}</b></span>
  } else if (services.length === 2) {
    services = <span><b>{services[0]}</b> and <b>{services[1]}</b></span>
  } else {
    services = services.map((service, idx) => {
      if (idx === services.length - 1) {
        return <span key={idx}>and <b key={service}>{service}</b></span>
      } else {
        return <span key={idx}><b key={service}>{service}</b>, </span>
      }
    })

  }


  let className = "Footer"

  if (visible) {
    className += " Footer--visible"
  } else {
    className += " Footer--hidden"
  }

  const successContent = (
    <div className={"Footer__success"}>
      <div className={"Footer__success-title"}>
        Success!
      </div>
      <div className={"Footer__success-subtitle"}>
        You should receive an email shortly with introductions to trusted service providers in your area.
      </div>
      <div
        className={"Footer__start-over"}
        onClick={() => {
          window.location = '/'
        }}
      >
        Start over
      </div>
    </div>
  )

  return (
    <>
      <div className={className}>
        <div className={"Footer__inner-box"}>
          <div className={"Footer__side-panel"}/>
          { success ? successContent :
            <div className={"Footer__content"}>
            <div className={"Footer__message"}>
              We’ll connect you with service providers in your area for {services}.
            </div>
            <div className={"Footer__capture"}>
              <div className={"Footer__label"}>
                Enter your email address to get connected:
              </div>
              <div className={"Footer__submitter"}>
                <InputGroup>
                  <FormControl
                    placeholder="Email"
                    aria-label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => updateEmail(e.target.value)}
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      style={{backgroundColor: '#EA0029', color: '#FFFFFF'}}
                      disabled={!email}
                      type={"submit"}
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </div>
          }
          <div className={"Footer__side-panel"}/>
        </div>
      </div>
    </>
  )
}

export default Footer