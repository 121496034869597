import "./layout.scss"

import React from "react"
import { Helmet } from "react-helmet"

import Header from "./Header"
import ProviderClassLayout from "./ProviderClassLayout"

const Layout = () => (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet" />
        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Header />
      <ProviderClassLayout />
    </>
)

Layout.propTypes = {
}

export default Layout
