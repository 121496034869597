
import React from 'react'

import ProviderClass from './ProviderClass'
import CleaningLogo from '../images/CleaningLogo'


import { CLEANING } from '../../common/constants'

const Cleaning = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={CleaningLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...CLEANING}
    />
  </>
)

export default Cleaning