import React from 'react'


export const CLEANING = {
  title: 'Cleaning',
  description: 'Trust us on this one! Hire a team to get your home sparkling clean before move-in.',
}

export const HOME_INSURANCE = {
  title: 'Home Insurance ',
  description: 'Shop around for homeowners insurance. Protect your home and possessions against accidental damage with the right home insurance. You’ll want to have a policy picked out before you close, so now is the perfect time to start researching.',
}

export const HOME_WARRANTY = {
  title: 'Home Warranty',
  description: 'Keep your home’s systems and appliances running smoothly without having to worry about the high cost of unexpected replacements or repairs. We recommend looking into coverage plans early on to get an idea of what’s right for you.',
}

export const HOME_SECURITY = {
  title: 'Home Security',
  description: 'Keep everything (and everyone) safe with a reliable home security system. It’s well worth the peace of mind.',
}

export const PRE_CLOSE_CONTRACT_SERVICES = {
  title: 'Contract Services Before Closing',
  description: 'Have confidence in your purchase by bringing in professionals to evaluate your home. Inspectors and general contractors can help you assess any damage to your home and help you get an estimate on repairs you may need.',
}

export const MOVERS = {
  title: 'Movers',
  description: 'No need to add heavy lifting or multiple trips to your plate. Professional movers will make sure your belongings are transferred swiftly and safely, while saving you a lot of sanity in the process.',
}

export const TITLE = {
  title: 'Title',
  description: 'As closing day approaches, you’ll want to work with a title company to verify the legitimacy of your property and ensure there are no outstanding issues.',
}

export const POST_CLOSE_CONTRACT_SERVICES = {
  title: 'Contract Services After Closing',
  description: 'Once you’ve moved in, it’ll be time to really make yourself at home. Contractors of all kinds can help you make your home your own.',
}

export const TITLE_FOR_SELECTION = {
  'home-insurance': 'Home Insurance',
  'home-security': 'Home Security',
  'home-warranty': 'Home Warranty',
  'cleaning': 'Cleaning',
  'pre-close-contract-services': 'Contract Services Before Close',
  'movers': 'Movers',
  'title': 'Title Services',
  'post-close-contract-services': 'Contract Services After Close',
}

export const STAGE_TO_FILTER_PHRASE = {
  spoke: <span>you're <b>speaking with an agent</b></span>,
  met: <span>you're <b>house hunting</b></span>,
  offer: <span>you've <b>made an offer</b></span>,
  contract: <span>you're <b>under contract</b></span>,
  closed: <span>you've <b>closed on a property</b></span>,
}
