import './HeroImage.scss'

import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import BackgroundImage from 'gatsby-background-image-es5'

const BackgroundSection = ({ children }) => (
    <StaticQuery
      query={graphql`
        query {
          desktop: file(relativePath: { eq: "stock-photo.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 4160) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        // Extract imageData.
        const imageData = data.desktop.childImageSharp.fluid
        return (
              <BackgroundImage
                Tag="section"
                className="HeroImage"
                fluid={imageData}
                backgroundColor={`#ffffff`}
                // Title get's passed to both container and noscriptImg.
                title="Service Providers"
                // You are able to set a classId and style by wrapper (see below or
                // https://github.com/timhagn/gatsby-background-image/#styling--passed-through-styles):
                // classId="gbi"
                // style={{
                //   // Defaults are overwrite-able by setting one of the following:
                //   // backgroundSize: '',
                //   // backgroundPosition: '',
                //   // backgroundRepeat: '',
                // }}
                // To "force" the classic fading in of every image (especially on
                // imageData change for fluid / fixed) by setting `soft` on `fadeIn`:
                // fadeIn={`soft`}
                // You can "safely" (look them up beforehand ; ) add other props:
                role="img"
                aria-label="Service Providers"
              >
                {children}
              </BackgroundImage>
        )
      }}
    />
  )

export default BackgroundSection