import './ProviderClassLayout.scss'

import _ from 'lodash'
import queryString from 'query-string'

import React, { useState } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase';

import { withStyles } from '@material-ui/core/styles';

import { encode } from '../common/util'

import Footer from './Footer'

import Cleaning from './ServiceProviderClasses/Cleaning'
import HomeInsurance from './ServiceProviderClasses/HomeInsurance'
import HomeSecurity from './ServiceProviderClasses/HomeSecurity'
import HomeWarranty from './ServiceProviderClasses/HomeWarranty'
import Movers from './ServiceProviderClasses/Movers'
import PostCloseContractServices from './ServiceProviderClasses/PostCloseContractServices'
import PreCloseContractServices from './ServiceProviderClasses/PreCloseContractServices'
import Title from './ServiceProviderClasses/Title'

import HeroImage from './HeroImage'

import { STAGE_TO_FILTER_PHRASE } from '../common/constants'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: '#ffffff',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 14px',
    textAlign: 'left',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 2,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      backgroundColor: '#ffffff',
    },
    minWidth: '35vh',
  },
}))(InputBase);

const filterByStage = stage => {
  switch (stage) {
    case 'spoke':
      return {
        homeInsurance: true,
        homeWarranty: true,
        homeSecurity: true,
        cleaning: false,
        preCloseContractServices: false,
        movers: true,
        title: false,
        postCloseContractServices: false,
      }
    case 'met':
      return {
        homeInsurance: true,
        homeWarranty: true,
        homeSecurity: false,
        cleaning: false,
        preCloseContractServices: false,
        movers: false,
        title: false,
        postCloseContractServices: false,
      }
    case 'offer':
      return {
        homeInsurance: false,
        homeWarranty: false,
        homeSecurity: false,
        cleaning: false,
        preCloseContractServices: true,
        movers: false,
        title: false,
        postCloseContractServices: false,
      }
    case 'contract':
      return {
        homeInsurance: true,
        homeWarranty: true,
        homeSecurity: false,
        cleaning: false,
        preCloseContractServices: true,
        movers: false,
        title: true,
        postCloseContractServices: false,
      }
    case 'closed':
      return {
        homeInsurance: false,
        homeWarranty: false,
        homeSecurity: true,
        cleaning: true,
        preCloseContractServices: false,
        movers: true,
        title: false,
        postCloseContractServices: true,
      }
    default:
      return {
        homeInsurance: true,
        homeWarranty: true,
        homeSecurity: true,
        cleaning: true,
        preCloseContractServices: true,
        movers: true,
        title: true,
        postCloseContractServices: true,
      }
  }
}

const ProviderClassLayout = () => {

  const [eventHash, updateEventHash] = useState(null)

  if (typeof window !== `undefined`) {
    const queryParams = queryString.parse(window.location.search)
    if (queryParams.hash) {
      updateEventHash(queryParams.hash)
      const newParams = queryString.stringify(_.omit(queryParams, 'hash'))
      const newURL = !_.isEmpty(newParams) ? window.location.origin + '/?' + newParams : window.location.origin
      window.history.replaceState(queryParams, 'Buyer\'s Guide', newURL)
    }
  }
  const [classSelection, updateClassSelection] = useState([])

  const [email, updateEmail] = useState('')
  const [transactionStage, updateTransactionStage] = useState('-')
  const [expandAll, updateExpandAll] = useState(false)
  const [success, updateSuccess] = useState(false)

  const changeTransactionStage = event => {
    updateTransactionStage(event.target.value)
    updateExpandAll(false)
  }

  const displayState = filterByStage(expandAll ? '' : transactionStage)

  const onSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "down-funnel",
        eventHash,
        email,
        classSelections: classSelection
      })
    })
      .then(() => {
        updateSuccess(true)
      })
      .catch(error => alert(error));

    e.preventDefault();
  }

  const makeClassUpdateHandler = (className) => (value) => {
    if (value) {
      updateClassSelection([...classSelection, className])
    } else {
      updateClassSelection(_.without(classSelection, className))
    }
  }

  const classSelectionContains = className => _.includes(classSelection, className)

  const footerVisible = _.some(classSelection)

  let footerSpaceClassName = "ProviderClassLayout__footer-space"
  if (footerVisible) {
    footerSpaceClassName += " ProviderClassLayout__footer-space--visible"
  }

  const showFilterSection = transactionStage !== '-' && !expandAll

  const filterTitleContent = (
    <div className={"ProviderClassLayout__filter-head"}>
      <div className={"ProviderClassLayout__filter-title"}>
        Services you should consider when {STAGE_TO_FILTER_PHRASE[transactionStage]}
      </div>
      <div
        className={"ProviderClassLayout__expand-all"}
        onClick={() => updateExpandAll(true)}
      >
        Show all services
      </div>
    </div>
  )

  const serviceProviderClassName = showFilterSection ? "ProviderClassLayout ProviderClassLayout--short-top" : "ProviderClassLayout"

  return (
    <>
      <HeroImage>
        <h1>Do you know what you<br /> need to buy a home?</h1>
        <p>Buying a home involves a variety of services along the way, but realtor.com is here to make the process a little easier.  Let us connect you with trusted service providers in your area, whatever stage of the process you may be in.</p>
        <Select
          value={transactionStage}
          onChange={changeTransactionStage}
          input={<BootstrapInput name="stage" id="stage-customized-select" />}
        >
          <MenuItem value="-">I'm currently...</MenuItem>
          <MenuItem value="spoke">I'm currently speaking with an agent</MenuItem>
          <MenuItem value="met">I'm currently house hunting</MenuItem>
          <MenuItem value="offer">I've made an offer on a property</MenuItem>
          <MenuItem value="contract">I'm under contract on a property</MenuItem>
          <MenuItem value="closed">I've closed on a property</MenuItem>
        </Select>
      </HeroImage>
        <div className={serviceProviderClassName}>
          <div className={"ProviderClassLayout__side-panel"} />
          <div className={"ProviderClassLayout__content"}>
            { showFilterSection && filterTitleContent}
            {displayState.homeInsurance && <HomeInsurance
              checked={classSelectionContains('home-insurance')}
              updateChecked={makeClassUpdateHandler('home-insurance')}
            />}
            {displayState.homeWarranty && <HomeWarranty
              checked={classSelectionContains('home-warranty')}
              updateChecked={makeClassUpdateHandler('home-warranty')}
            />}
            {displayState.homeSecurity && <HomeSecurity
              checked={classSelectionContains('home-security')}
              updateChecked={makeClassUpdateHandler('home-security')}
            />}
            {displayState.cleaning && <Cleaning
              checked={classSelectionContains('cleaning')}
              updateChecked={makeClassUpdateHandler('cleaning')}
            />}
            {displayState.preCloseContractServices && <PreCloseContractServices
              checked={classSelectionContains('pre-close-contract-services')}
              updateChecked={makeClassUpdateHandler('pre-close-contract-services')}
            />}
            {displayState.movers && <Movers
              checked={classSelectionContains('movers')}
              updateChecked={makeClassUpdateHandler('movers')}
            />}
            {displayState.title && <Title
              checked={classSelectionContains('title')}
              updateChecked={makeClassUpdateHandler('title')}
            />}
            {displayState.postCloseContractServices && <PostCloseContractServices
              checked={classSelectionContains('post-close-contract-services')}
              updateChecked={makeClassUpdateHandler('post-close-contract-services')}
            />}
          </div>
          <div className={"ProviderClassLayout__side-panel"} />
        </div>
        <div className={footerSpaceClassName} />
        <Footer
          selections={classSelection}
          email={email}
          updateEmail={updateEmail}
          onSubmit={onSubmit}
          visible={footerVisible}
          success={success}
        />
    </>
  )
}

export default ProviderClassLayout
