
import React from 'react'

import ProviderClass from './ProviderClass'
import ContractServicesLogo from '../images/ContractServicesLogo'


import { PRE_CLOSE_CONTRACT_SERVICES } from '../../common/constants'

const PreCloseContractServices = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={ContractServicesLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...PRE_CLOSE_CONTRACT_SERVICES}
    />
  </>
)

export default PreCloseContractServices
