
import React from 'react'

import ProviderClass from './ProviderClass'
import HomeWarrantyLogo from '../images/HomeWarrantyLogo'


import { HOME_WARRANTY } from '../../common/constants'

const HomeWarranty = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={HomeWarrantyLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...HOME_WARRANTY}
    />
  </>
)

export default HomeWarranty
