
import React from 'react'

import ProviderClass from './ProviderClass'
import MoversLogo from '../images/MoversLogo'


import { MOVERS } from '../../common/constants'

const Movers = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={MoversLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...MOVERS}
    />
  </>
)

export default Movers
