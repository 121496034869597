import '../styles/Header.scss'

import React from "react"
import RDCLogo from './images/RDCLogo'

const Header = () => (
  <header className={"Header"}>
    <div className={"Header__logo"}>
      <RDCLogo />
    </div>
  </header>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
