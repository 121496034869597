
import React from 'react'

import ProviderClass from './ProviderClass'
import HomeInsuranceLogo from '../images/HomeInsuranceLogo'


import { HOME_INSURANCE } from '../../common/constants'

const HomeInsurance = ({checked, updateChecked}) => (
  <>
    <ProviderClass
      logoComponent={HomeInsuranceLogo}
      checked={checked}
      updateChecked={updateChecked}
      {...HOME_INSURANCE}
    />
  </>
)

export default HomeInsurance
